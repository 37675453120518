import React from "react";
import { AppLink } from "@base";
import { Text, Image, Container, Button } from "@atoms";

const Featured = ({ heading, copy, image, link, button }) => {
  return (
    <section className="relative my-10 overflow-hidden bg-purple/10 bg-text bg-cover py-10 pb-20 sm:my-20 sm:py-20">
      <Container padding>
        <div className="relative flex flex-col items-center gap-12">
          <div className="flex w-full flex-wrap items-stretch gap-12 lg:flex-nowrap xl:gap-24">
            <div className="w-full lg:w-1/2">
              <div className="relative h-full min-h-1/4-screen">
                <Image
                  image={image}
                  fill
                  className="rounded-l-3xl rounded-br-6xl rounded-tr-3xl shadow-xl"
                />
              </div>
            </div>
            <div className="relative w-full lg:w-1/2 lg:py-8">
              <div className="flex h-full w-full flex-col gap-6">
                <Text variant="h3" className="text-purple">
                  {heading}
                </Text>
                <div className="flex flex-col gap-6 rounded-t-xl rounded-br-xl bg-white p-6 shadow-xl">
                  <Text richText>{copy}</Text>
                  {link?.url && (
                    <AppLink
                      to={link.url}
                      className="text-sm font-bold leading-normal text-pink underline decoration-inherit"
                    >
                      {link.text || "Learn More"}
                    </AppLink>
                  )}
                </div>
              </div>
            </div>
          </div>
          {button?.url && (
            <Button to={button.url} color="purple" size="sm">
              {button.text || "Learn More"}
            </Button>
          )}
        </div>
      </Container>
    </section>
  );
};

export default Featured;
